// Node Modules
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Components
import ContentOverImage from '../../resuables/ContentOverImage';
import YellowButton from '../../resuables/YellowButton';

// Styles
import { above, colors, space } from '../../../style/design-system';

// Utils
import { scrollToJoinUsForm } from '../../../util/functions/scrollToJoinUsForm';

const WhyWeCareHero = ({ className }) => {
  const query = useStaticQuery(graphql`{
    heroImage: file(relativePath: {eq: "why-we-care/why-we-care-hero.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED, width: 1920)
      }
    }
  }`);

  return (
    <div className={className}>
      <ContentOverImage
        alt="Intertwined ropes in various shades of green and blue"
        dripHeight="200px"
        dripColor="#fff"
        dripLeft="200px"
        includeDrip
        gatsbyImage={query.heroImage.childImageSharp.gatsbyImageData}
        renderContent={() => (
          <div className="content d-flex ai-c">
            <div className="container">
              <div className="row">
                <div className="col-10 offset-1 col-sm-10 col-md-8 offset-md-0 col-lg-6 col-xl-5 offset-xl-1">
                  <div className="white-box">
                    <p className="my-0">We care for students. They often care for others. Care pays back &mdash; creating a ripple effect of care one student at a&nbsp;time.</p>
                  </div>
                  <div className="d-flex jc-fe">
                    <YellowButton handleClickInParent={scrollToJoinUsForm}>
                      Join Our Care Community
                    </YellowButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} />
    </div>
  );
};

export default styled(WhyWeCareHero)`
  margin-bottom: -3rem;
  ${above.md`
  margin-bottom: 0;
  `}
  .content {
    transform: translateY(-3rem);
    ${above.md`
      height: 18rem;
      transform: translateY(0);
    `}
    ${above.lg`
      height: 24rem;
    `}
    ${above.xl`
      height: 28rem;
    `}
  }
  .white-box {
    background-color: #fff;
    border: 1px solid ${colors.umaBlack};
    padding: ${space.f};
  }
`;

WhyWeCareHero.propTypes = {
  className: PropTypes.string.isRequired,
};
