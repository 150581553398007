// Node Modules
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Style
import { space } from '../../../style/design-system';

const WhyWeCareText = ({ className }) => (
  <div className={className}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
          <div className="above-blog-links text-center">
            <h2 className="text-center">Life is more rewarding when we care for&nbsp;others.</h2>
            <p className="text-center">The CarePaysBack.org mission aims to create a community of care and an open source of support for students. When we all care, people grow stronger, and communities&nbsp;thrive.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default styled(WhyWeCareText)`
  padding: ${space.g} 0;
`;

WhyWeCareText.propTypes = {
  className: PropTypes.string.isRequired,
};
