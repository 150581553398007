// Node Modules
import * as React from 'react';

// Components
import JoinUsFormWrapper from '../components/form/presentational/JoinUsFormWrapper';
import Layout from '../components/core/layout';
import SEO from '../components/core/seo';
import WhyWeCareHero from '../components/content-sections/why-we-care/WhyWeCareHero';
import WhyWeCareText from '../components/content-sections/why-we-care/WhyWeCareText';

const WhyWeCarePage = () => (
  <Layout>
    <SEO title="Why We Care" />
    <WhyWeCareHero />
    <WhyWeCareText />
    <JoinUsFormWrapper hideCourses />
  </Layout>
);

export default WhyWeCarePage;
